(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();

var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};

import swal from 'sweetalert';
export const errorMessage = 'Error al procesar solicitud';

function emp(value) {
  return value == null || value.toString().trim().length === 0;
}

function getTitle(type) {
  switch (type) {
    case 1:
      return 'Success!';

    case 2:
      return 'Warning!';

    case 3:
      return 'Error!';

    default:
      return 'Info!';
  }
}

function getClassName(type) {
  switch (type) {
    case 1:
      return 'success';

    case 2:
      return 'warning';

    case 3:
      return 'error';

    default:
      return 'info';
  }
}

export function message(msg, type = '1', title = '', typemsg = 1) {
  let finalTitle = title;

  if (emp(finalTitle)) {
    finalTitle = getTitle(type);
  }

  if (typemsg === 1 || typemsg === 0) {
    const className = getClassName(type);
    console.log({
      title: finalTitle,
      text: msg,
      icon: className
    });
    swal({
      title: finalTitle,
      text: msg,
      icon: className
    });
  }
}
export async function customConfirm(text) {
  const result = await swal({
    text,
    buttons: {
      cancel: {
        visible: true,
        text: 'Cancel'
      },
      confirm: {
        text: 'Accept'
      }
    },
    title: '¡Advertencia!',
    icon: 'warning'
  });
  return result;
}
;

(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;

  if (!reactHotLoader) {
    return;
  }

  reactHotLoader.register(errorMessage, "errorMessage", "/var/www/html/websites/thedistrictusc-com/src/frontend/utils/notification.js");
  reactHotLoader.register(emp, "emp", "/var/www/html/websites/thedistrictusc-com/src/frontend/utils/notification.js");
  reactHotLoader.register(getTitle, "getTitle", "/var/www/html/websites/thedistrictusc-com/src/frontend/utils/notification.js");
  reactHotLoader.register(getClassName, "getClassName", "/var/www/html/websites/thedistrictusc-com/src/frontend/utils/notification.js");
  reactHotLoader.register(message, "message", "/var/www/html/websites/thedistrictusc-com/src/frontend/utils/notification.js");
  reactHotLoader.register(customConfirm, "customConfirm", "/var/www/html/websites/thedistrictusc-com/src/frontend/utils/notification.js");
})();

;

(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();